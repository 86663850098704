.ProjectContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: auto;
    height: auto;
}

.ProjectImg {
    display: flex;
    height: 50%;
    width: 40%;
    margin: auto;
}

.FreelanceLink {
    display: inline-block;
    text-align: center;
    padding: 15px;
}

.ProjectDescription {
    display: flex;
    text-align: center;
    overflow: hidden;
    padding: 2rem;
    padding-left: 5rem;
}

.Built {
    display: flex;
    justify-content: center;
    text-decoration: underline;
}

.ProjectTech {
    display: flex;
    justify-content: center;
}

.ProjectLinkCont {
    display: flex;
    overflow: hidden;
    justify-content: center;
}

.ProjectThumbnail {
    display: flex;
    width: auto;
    height: 15rem;
    border-radius: 2%;
    transition: box-shadow 0.5s ease-in-out;
}

@media screen and (max-width: 1000px) {
    .ProjectThumbnail {
        margin: 2rem auto;
    }
    .ProjectBoxes {
        flex-direction: column;
        gap: unset;
        padding: unset;
    }
}

@media screen and (max-width: 370px) {
    .ProjectThumbnail {
        width: 20rem;
        height: 15rem;
    }
}

@media screen and (max-width: 428px) {
    .ProjectThumbnail {
        width: 22rem;
        height: 20rem;
    }
}

.ProjectThumbnail:hover {
    transition: box-shadow 0.5s ease-in-out;
    box-shadow: 10px 15px 10px var(--primary-accent);
}

.ProjectBoxes {
    display: flex;
    padding: 20px;
    gap: 3rem;
    justify-content: center;
}
