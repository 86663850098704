.skillsHeader {
    font-size: 35px;
    text-align: center;
}

.skillsContainer {
    display: grid;
    border-top: 3px solid slategray;
    justify-content: center;
    grid-template-columns: repeat(5, 80px);
    grid-auto-rows: 100px;
    grid-gap: 30px;
    width: auto;
    padding: 6rem;
}

@media screen and (max-width: 375px) {
    .skillsContainer {
        grid-template-columns: repeat(3, 5rem);
    }
}

@media screen and (max-width: 552px) {
    .skillsContainer {
        grid-template-columns: repeat(3, 5rem);
    }
}

.skillsContainer svg {
    font-size: 100px;
    width: 75%;
    margin: auto;
}

.skillsIcon:hover {
    transform: scale(1.5);
    transition: 0.25s;
}

.skillsSpan {
    font-size: 20px;
}

.skillsBlock {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
