.nav {
    background-color: var(--primary-accent);
    text-transform: uppercase;
    text-align: center;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2;
    height: 4rem;
    align-items: center;
    padding: 5px;
}

.nav a {
    color: white;
    text-decoration: none;
}

.nav :global(.active) {
    text-decoration: underline;
    border-radius: 15%;
    transform: scale(1.25);
}
.nav a:hover {
    transform: scale(1.25);
    transition: 0.35s;
}

.navLinksBox {
    display: flex;
}

.navName {
    display: flex;
    justify-content: flex-start;
    color: white;
    padding: 1.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
}
.navNameShort {
    display: none;
    display: flex;
    justify-content: flex-start;
    color: white;
    padding: 1.5rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 3rem;
    display: none;
    margin: -1rem;
}

@media (max-width: 824px) {
    .navNameShort {
        display: inline-block;
        margin: -1rem;
    }
    .navName {
        display: none;
    }
}

.linkGroup {
    display: flex;
    gap: 3rem;
    margin: 2rem;
}

@media screen and (max-width: 450) {
    .linkGroup {
        padding: unset;
        margin: 2rem;
        gap: 3rem;
    }
}
@media screen and (max-width: 370px) {
    .linkGroup {
        margin: -3rem;
        gap: 1.5rem;
    }
}
