.footer {
    background-color: var(--primary-accent);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1.5rem;
}

.footerLinks {
    display: flex;
    justify-content: center;
    gap: 7rem;
}

@media screen and (max-width: 370px) {
    .footerLinks {
        gap: 1rem;
    }
}

@media screen and (max-width: 428px) {
    .footerLinks {
        gap: 1rem;
    }
}

@media screen and (max-width: 550px) {
    .footerLinks {
        gap: 2rem;
    }
}

.Copyright {
    color: white;
    margin: auto;
    padding: 2px;
}

.footerLinks a svg:hover {
    /* filter: invert(0.5) sepia(3) hue-rotate(200deg) saturate(5) brightness(1); */
    filter: invert(1);
}
