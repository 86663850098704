:root {
    --nav-height: 80px;
    --nav-vertical-padding: 20px;
    --nav-adjusted-height: 100px;
    --footer-height: 40px;
    --content-height: 140px;
    --primary-accent: steelblue;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

main {
    min-height: calc(100vh - var(--nav-adjusted-height));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: var(--nav-adjusted-height);
    gap: 1rem;
}
